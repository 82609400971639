// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Injectable } from '@angular/core';

// import { Observable, throwError, EMPTY} from 'rxjs';
// import { catchError, map, finalize,switchMap } from 'rxjs/operators';
// import { CsrfService } from './csrf.service';
// // import { environment } from '../../../environments/environment';
// interface csrfToken {
//     csrf_token: string;
//   }
// @Injectable({
//     providedIn: 'root'
// })
// export class HttpService {

//     public monitoring = {
//         pendingRequestsNumber: 0
//     };

//     constructor(
//         private http: HttpClient, private csrfService: CsrfService
//     ) {

//     }



//     getMethod(url: string): Observable<any> {
//                 try {
//                     if (url) {  
//                         this.showSpinner();
//                         return this.http.get(url).pipe(
//                             map(resp => this.handleResponse(resp)),
//                             catchError((err) => this.handleError(err)), finalize(() => {
//                                 this.hideSpinner();
//                             })
//                         );
//                     }
//                     else {
//                         return EMPTY
//                     }
//                 } catch (error) {
//                 }
//             }
//             postMethod(url: string, payload: any): Observable<any> {
//                 try {
//                   if (url) {
//                     this.showSpinner();
//                     return this.csrfService.getCsrfToken().pipe(
//                       switchMap((csrfToken: any) => {
//                         if (!csrfToken) {
//                           // Handle the case where CSRF token is not available
//                           return EMPTY;
//                         }
            
//                         // Include the CSRF token in the headers
//                         const headers = new HttpHeaders({
//                           'Content-Type': 'application/json',
//                           'X-CSRFToken': csrfToken.csrf_token
//                         });
//                         const options = { headers, withCredentials: true };
//                         console.log(csrfToken.csrf_token)
//                         return this.http.post(url, payload, options).pipe(
//                           catchError(err => this.handleError(err)),
//                           finalize(() => this.hideSpinner())
//                         );
//                       })
//                     );
//                   } else {
//                     return EMPTY;
//                   }
//                 } catch (error) {
//                   console.trace(error);
//                   return throwError(error);
//                 }
//               }
//     /** handle error */
//     handleError(error) {
//         if (error.status === 500 || error.status === 0) {
//         }
//         console.log(error)
//         let errorMessage = '';
//         if (error.error instanceof ErrorEvent) {
//             // client-side error
//             errorMessage = `Error: ${error.error.message}`;
//         } else {
//             // server-side error
//             errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
//         }

//         if (error.status === 401) {
//             // Unauthorized access action...
//             // const currentLocation = location.href;
//             // window.location.href = environment.idmLogOutUrl + currentLocation;

//             return EMPTY;
//         } else {
//             return throwError(errorMessage);
//         }
//     }

//     /** */
//     handleResponse(resp) {
//         if (resp) {
//             return resp;
//         }
//     }
//     showSpinner() {
//         this.monitoring.pendingRequestsNumber++;
//     }
//     hideSpinner() {
//         this.monitoring.pendingRequestsNumber--;
//         if (this.monitoring.pendingRequestsNumber === 0) {
//         }
//     }
// }


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
// import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class HttpService {

    public monitoring = {
        pendingRequestsNumber: 0
    };

    constructor(
        private http: HttpClient,
    ) {

    }
    getMethod(url: string): Observable<any> {
        try {
            if (url) {  
                this.showSpinner();
                return this.http.get(url).pipe(
                    map(resp => this.handleResponse(resp)),
                    catchError((err) => this.handleError(err)), finalize(() => {
                        this.hideSpinner();
                    })
                );
            }
            else {
                return EMPTY
            }
        } catch (error) {
        }
    }

    postMethod(url: string, payload: any): Observable<any> {
        try {
            if (url) {
                this.showSpinner()
                return this.http.post(url, payload).pipe(
                    map(resp => this.handleResponse(resp)),
                    catchError((err) => this.handleError(err)), finalize(() => {
                        this.hideSpinner()
                    })
                );
            }
            else {
                return EMPTY
            }
        } catch (error) {
        }
    }

    /** handle error */
    handleError(error) {
        if (error.status === 500 || error.status === 0) {
        }
        console.log(error)
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        if (error.status === 401) {
            // Unauthorized access action...
            // const currentLocation = location.href;
            // window.location.href = environment.idmLogOutUrl + currentLocation;

            return EMPTY;
        } else {
            return throwError(errorMessage);
        }
    }

    /** */
    handleResponse(resp) {
        if (resp) {
            return resp;
        }
    }
    showSpinner() {
        this.monitoring.pendingRequestsNumber++;
    }
    hideSpinner() {
        this.monitoring.pendingRequestsNumber--;
        if (this.monitoring.pendingRequestsNumber === 0) {
        }
    }
}



