import { Component } from '@angular/core';
// import { GuidedTourService, Orientation } from 'ngx-guided-tour';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'allergan-eye-care';

  // public dashboardTour: GuidedTour = {
  //   tourId: 'portal-tour',
  //   useOrb: true,
  //   steps: [
  //     {
  //       title: 'ShipTo Search Page',
  //       content: 'Welcome to ShipTo search page. Here you can search for ShipTo using various filters and take export too.',
  //     },
  //     {
  //       title: 'ShipTo basic filter',
  //       selector: 'app-basic-filter',
  //       content: 'This are basic ShipTo search filters. Using these will help you to search for a specific ShipTo.',
  //       orientation: Orientation.Bottom
  //     },
  //     {
  //       title: 'Advance Filters',
  //       selector: 'app-advanced-filter',
  //       content: 'These section contains more search filters for ShipTo like Account Group affiliation, Sales criteria etc.',
  //       orientation: Orientation.Top
  //     },
  //     {
  //       title: 'Chat Assistant',
  //       selector: '.focus-outline-round',
  //       content: 'This is Abbvie Chat assistant. This can answer your queries in detail.',
  //       orientation: Orientation.Left
  //     }
  //   ]
  // };

  // constructor(
  //   private guidedTourService: GuidedTourService
  // ) {

  //   setTimeout(() => {
  //     this.guidedTourService.startTour(this.dashboardTour);
  //   }, 1000);

  // }


  ngOnInit(): void {
    sessionStorage.clear();
  }

  //   public restartTour(): void {
  //     this.guidedTourService.startTour(this.dashboardTour);
  //   }


  // }

  // interface OrientationConfiguration {
  //   /** Where the tour step will appear next to the selected element */
  //   orientationDirection: Orientation,
  //   /** When this orientation configuration starts in pixels */
  //   maximumSize?: number
  // }

  // interface TourStep {
  //   /** Selector for element that will be highlighted */
  //   selector?: string;
  //   /** Tour title text */
  //   title?: string;
  //   /** Tour step text */
  //   content: string;
  //   /** Where the tour step will appear next to the selected element */
  //   orientation?: Orientation | OrientationConfiguration[];
  //   /** Action that happens when the step is opened */
  //   action?: () => void;
  //   /** Action that happens when the step is closed */
  //   closeAction?: () => void;
  //   /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
  //   skipStep?: boolean;
  //   /** Adds some padding for things like sticky headers when scrolling to an element */
  //   scrollAdjustment?: number;
  //   /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
  //   useHighlightPadding?: boolean;
  //   /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
  //   highlightPadding?: number;
  // }

  // interface GuidedTour {
  //   /** Identifier for tour */
  //   tourId: string;
  //   /** Use orb to start tour */
  //   useOrb?: boolean;
  //   /** Steps for the tour */
  //   steps: TourStep[];
  //   /** Function will be called when tour is skipped */
  //   skipCallback?: (stepSkippedOn: number) => void;
  //   /** Function will be called when tour is completed */
  //   completeCallback?: () => void;
  //   /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
  //   minimumScreenSize?: number;
  //   /** Dialog shown if the window width is smaller than the defined minimum screen size. */
  //   resizeDialog?: {
  //     /** Resize dialog title text */
  //     title?: string;
  //     /** Resize dialog text */
  //     content: string;
  //   }
}
