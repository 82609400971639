// error message for something went wrong
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.';

export const LOCAL_SERVICE_NAMES = {
    // ShipTo Search
    shipToSearchFilters: 'assets/json/getShipToSearchFilters.json',
    getShipToGridData: 'assets/json/getShipToLoadSearchData.json',
    shipToSalesLast12Months: 'assets/json/getUnallocatedSalesData_12.json',
    shipToSalesLast30Days: 'assets/json/getShiptoUnallocatedSales_30.json',
    // ShipTo Details
    fetchHelpPageDetails: 'assets/json/helpPageDetails.json',
    getGridData: 'assets/json/getShipToGridValues.json',
    basicShipToDetails: 'assets/json/basicShipToDetails.json',
    physicianAllocationBodyContent: 'assets/json/physicianAllocationBodyContent.json',
    shipToSalesBodyContent: 'assets/json/shipToSalesBodyContent.json',
    timePeriodPhysicianAllocation: 'assets/json/timePeriodPhysicianAllocation.json',
    accountGroupAffiliationBodyContent: 'assets/json/accountGroupAffiliationBodyContent.json',
    directAlignmentDropDowns: 'assets/json/directAlignmentDropDowns.json',
    accountGroupChangesBodyContent: 'assets/json/historyOfChangesSampleAccountGroup.json',
    shipToChangesBodyContent: 'assets/json/historyOfChangesSampleShipTo.json',
    physicianChangesBodyContent: 'assets/json/historyOfChangesSample.json',
    accountGroupPopupSearchResults: 'assets/json/shipToAccountGroupSearchResults.json',
    shipToSearchAccountGroupDropDowns: 'assets/json/shipToSearchAccountGroupDropDowns.json',
    AffiliatedShipTosGrid: 'assets/json/AffiliatedShipTosGrid.json',
    shipToPopupSearchResults: 'assets/json/shipToPopupSearchResults.json',
    physicianSearchPopupDropDowns: 'assets/json/physicianSearchPopupDropDowns.json',
    physicianSearchPopupResults: 'assets/json/physicianSearchPopupResults.json',
    productList: 'assets/json/productList.json',
    alignmentInfo: 'assets/json/alignmentInfo.json',
    physicianAllocationPopupDropDowns: 'assets/json/physicianAllocationPopupDropDowns.json',

    // Physician Search
    physicianSearchFilters: 'assets/json/getPhysicianSearchFilters.json',
    getPhysicianGridData: 'assets/json/getPhysicianLoadSearchData.json',
    basicPhysicianDetails: 'assets/json/basicPhysicianDetails.json',
    allocationBodyContent: 'assets/json/allocationBodyContent.json',
    addNewPhysicianDropdowns: 'assets/json/addNewPhysiciansDropdowns.json',

    //
    getDetailsOfZip: 'assets/json/getDetailsOfZip.json',

    //Account group
    accountGroupSearchResult: 'assets/json/accountGroupGridResults.json',
    getAGBasicDetails: 'assets/json/accountGroupBasicDetails.json',
    accountGroupAffiliationGrid: 'assets/json/AG_shipToAffiliationGridBody.json',


    //Zip code

    zipSearchResults: 'assets/json/zipSearchResults.json',
    // Change Request Search
    changeRequestSearchFilters: 'assets/json/getChangeRequestSearchFilters.json',
    getChangeRequestGridData: 'assets/json/getChangeRequestLoadSearchData.json',

    // Change Request Details
    getChangeRequestBasicDetails: 'assets/json/getChangeRequestBasicDetails.json',
    getMovedPhysicianDetails: 'assets/json/getChangeRequestMovedPhysBasicDetails.json',
    getMovedPhysicianCRDetails: 'assets/json/getMovedPhysicianContactDetails.json',
    getMovedPhysActivityandNotes: 'assets/json/getMovedPhysActivity&Notes.json',
    getMovedPhysSubmissionDetails: 'assets/json/getMovedPhysicianSubmissionDetails.json',
    getActivityandNotes: 'assets/json/getActivity&Notes.json',
    getPhysDetailsAddNewPhys: 'assets/json/getPhysicianDetailsAddNewPhys.json',
    getDirectAlignedDetials: 'assets/json/getDirectAlignedCR.json',
    changeRequestNewOwner: 'assets/json/changeRequestNewOwner.json'
};


export const LIVE_SERVICE_NAMES = {

    getUserDetails: 'getUserInfo',
    //shipTo search
    shipToPopupSearchResults: 'getShipToLoadSearchData',
    shipToSearchFilters: 'getShipToDropDownData',
    cascadingFilters: 'getShipToDropDownData',
    getSegment: 'getSegmentData',
    getStateList: 'getStateValue',
    getShipToGridData: 'getShipToLoadSearchData',
    shipToSalesLast12Months: 'getUnallocatedSalesData_12',
    shipToSalesLast30Days: 'getShiptoUnallocatedSales_30',
    updateDirectAlignment: 'updateDirectAlignmentShipto',
    addUpdateAllocation: 'updateShiptoPhysicianAllocation',
    addUpdateAffiliation: 'updateAGAffiliation',

    //cascading dropDowns
    getRegionList: 'getDivision',
    getDistrictList: 'getRegionForDivision',
    getTerritoryListForDistrict: 'getTerritoryforRegion',
    getTerritoryListForRegion: 'getTerrForDiv',

    getSubSpecialtyDropDown : 'getSubSpecialtyDropDown',
    getUsageSegmentDropDown : 'getUsageSegmentDropDown',
    getTrainingStageDropDown : 'getTrainingStageDropDown',
    //
    // shipTo details
    basicShipToDetails: 'getShipToDetails',
    shipToSalesBodyContent: 'getShiptoSalesData',
    physicianAllocationBodyContent: 'getShiptoCurrentPhysicianAllocation',
    accountGroupAffiliationBodyContent: 'getShipToAccountGroupAffiliation',
    productList: 'getShipToProduct',
    timePeriodPhysicianAllocation: 'getTimePeriodDropDown',
    getDisclaimerShipToDetails: 'getShipTodisclaimer',
    getDisclaimerPhysicianDetails: 'getDisclaimerPhysician',
    getDisclaimerAGDetails: 'getagdisclaimer',
    getBaselineDetails: 'getBaselineSalesflag',
    getCycleList : 'getCycleList',

    // Change Request Search
    changeRequestSearchFilters: 'getcrdropdowndata',
    getChangeRequestGridData: 'searchchangerequest',
    getChangeRequestGridDataDASplits: 'exportchangerequest',

    // Change Request Details
    getChangeRequestBasicDetails: 'getcrsubmissiondetails',
    getMovedPhysicianDetails: 'assets/json/getChangeRequestMovedPhysBasicDetails.json',
    getMovedPhysicianCRDetails: 'getcrcontactdetails',
    getActivity: 'getactivityforrequest',
    getShipToAffiliationCRDetails: 'getaffiliatedshipto',
    getNotes: 'getcrnotes',
    getPhysDetailsAddNewPhys: 'getcrphysiciandetails',
    getDirectAlignedDetials: 'assets/json/getDirectAlignedCR.json',
    getOwnerList: 'getOwnerDetails',
    getOwnerListDirectAlignment: 'getOwnerDetailsDirect',
    approveDirectAlignment: 'submitDirectAlignmentRequest',
    approveAddNewPhysician: 'submitaddnewphysicianrequest',
    approveMovedPhysician: 'updateMovedPhysician',
    addNewPhysicianSaveAsDraft: 'addNewPhysicianDraft',
    addUpdateAllocationSaveAsDraft: 'updatePhysicianAllocationFromDraft',
    allocationCrDetails: 'getcrphysicianallocation',
    directAlignmentDetails: 'getcrdirectalignmentdetails',
    addNewAGCRDetails: 'getaddnewaccountgroupinfo',
    AGDetailsUpdateAGProfile: 'getUpdateAccountGroupProfile',
    TargetEligibilityDetails: 'getcrtargeteligibilitydetails',
    editAndApproveAddNewPhysician: 'editAndApproveAddNewPhysician',
    editAndApproveAddress: 'editAndApproveAddress',
    submitCampaignActivity: 'submitCampaignActivity',
    getPhysicianCampaignCRDetails: 'getPhysicianCampaignCRDetails',
    getPhysSubSpecialty : 'getPhysSubSpecialtyCR',
    getRetiredFlagCRDetails : 'getRetiredFlagCRDetails',
    refreshAddNewCR: 'refreshAddNewCR',


    //Zip code
    zipSearchResults: 'getSearchZIP',
    getDetailsOfZip: 'getZipInfo',

    //Account Group
    accountGroupSearchResult: 'getAccountGroupSearch',
    getAGBasicDetails: 'getaccountgroupinfo',
    accountGroupAffiliationGrid: 'getCurrentShipToAffiliations',
    addNewAccountGroup: 'addAccountGroupDetails',
    updateAccountGroup: 'updateAccountGroupProfile',
    //physician
    getPhysicianStartDateEndDate: 'getDropdownDataPhysician',
    getPhysicianDropDown: 'getDropDownForAddPhysician',
    getPhysicianGridData: 'searchPhysician',
    basicPhysicianDetails: 'getPhysicianDetails',
    allocationBodyContentForPhysician: 'getCurrentPhysicianAllocation_physician',
    physicianSalesBodyContent: 'getSalesForPhysician',
    updateAddress: 'updatePhysicianAddress',
    updateCalledOnStatus: 'updateTargetStatus',
    addNewPhysician: 'addNewPhysician',
    getReasonsList: 'getReasons',
    getReasonsListAllocation: 'getPhysiciansMovedReasons',
    getCalledOnStatusReasonsList: 'getEligibilityReasons',
    assignNewOwner: 'assignNewOwner',
    updateAddressSaveAsDraft: 'updateAddressDraft',
    submitSubSpecialty: 'submitSubSpecialty',
    reactivePhysician: 'reactivePhysician',
    getRetiredFlag: 'getRetiredFlag',
    removeRetiredFlag : 'removeRetiredFlag',
    getMultiSpecialty: 'getMultiSpecialty',
    //campaign
    getCampaignList : 'getCampaignList',
    getActivityList : 'getActivityList',
    getPhysicaincampaignDataList : 'getPhysicianCampaignData',
    approveRejectTES: 'approveRejectTargetEligibilityStatus',

    //help page
    fetchDocumentDetails: 'getDocuments',
    fetchHelpPageDetails: 'getPrimaryContact',
    uploadDocument: 'uploadDocument',
    fetchDocuments: 'fetchDocuments',
    removeDocument: 'removeDocument',

    //History of changes

    accountGroupChangesBodyContent: 'getHistoryOfChanges',
    shipToChangesBodyContent: 'getShiptoHistoryData',
    physicianChangesBodyContent: 'getPhysicianHistoryChanges',
};