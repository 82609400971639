import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpHeaders, } from '@angular/common/http';
import { HttpResponse, HttpUserEvent, HttpProgressEvent } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../utils/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService
  ) {

  }

  addCommonHttpOptions(request): HttpRequest<any> {
    // const req = request.clone({
    //   withCredentials: true,
    //   setHeaders: {
    //     UserDetails: this.localStorageService.getStorage('userDetails') ? this.localStorageService.getStorage('userDetails') : '',
    //     ProductDetails: this.localStorageService.getStorage('productDetails') ? this.localStorageService.getStorage('productDetails') : ''
    //   }
    // });
    // req.withCredentials = true;
    // return req;
      try {
            const req = request.clone({
                setHeaders: {
                UserDetails: this.localStorageService.getStorage('userDetails') ? this.localStorageService.getStorage('userDetails') : '',
                ProductDetails: this.localStorageService.getStorage('productDetails') ? this.localStorageService.getStorage('productDetails') : ''
              }
            });
            req.withCredentials = true;
            return req;
        } catch (error) {
            console.log(error);
        }

  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent |
    HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const custom_request = this.addCommonHttpOptions(req);
    return next.handle(custom_request).pipe(
        catchError(err => {
        if (err.status === 401) {
          const currentLocation = location.href;
          window.location.href = environment.idmLogInUrl
          return EMPTY;
        } else {
          return throwError(err);
        }
      })
    );
  }
}
