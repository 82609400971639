<router-outlet></router-outlet>

<!-- <df-messenger
  df-cx="true"
  location="us-central1"
  chat-title="AbbVie Assistant"
  agent-id="550a39eb-7242-4c2c-b13f-b310ea3b095a"
  language-code="en"
  intent="Default Trigger"
  wait-open="true"
></df-messenger>  -->

<!-- <ngx-guided-tour></ngx-guided-tour> -->